exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-cancel-subscription-tsx": () => import("./../../../src/pages/cancel-subscription.tsx" /* webpackChunkName: "component---src-pages-cancel-subscription-tsx" */),
  "component---src-pages-confirm-subscription-tsx": () => import("./../../../src/pages/confirm-subscription.tsx" /* webpackChunkName: "component---src-pages-confirm-subscription-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-download-ebooks-tsx": () => import("./../../../src/pages/download-ebooks.tsx" /* webpackChunkName: "component---src-pages-download-ebooks-tsx" */),
  "component---src-pages-feedback-gratuito-sceneggiatura-tsx": () => import("./../../../src/pages/feedback-gratuito-sceneggiatura.tsx" /* webpackChunkName: "component---src-pages-feedback-gratuito-sceneggiatura-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-templates-blog-category-tsx": () => import("./../../../src/templates/blog-category.tsx" /* webpackChunkName: "component---src-templates-blog-category-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog-tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */)
}

