import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useRef,
} from 'react';

type IntersectionObserverType = {
  containerRef: React.MutableRefObject<any>;
  isVisible: boolean;
  setIsVisible: Dispatch<SetStateAction<boolean>>;
};

const defaultValues: IntersectionObserverType = {
  containerRef: null,
  isVisible: false,
  setIsVisible: null,
};

const IntersectionObserverContext =
  React.createContext<IntersectionObserverType>(defaultValues);

const IntersectionObserverProvider = ({ children }) => {
  const containerRef = useRef<HTMLInputElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  return (
    <IntersectionObserverContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ containerRef, isVisible, setIsVisible }}
    >
      {children}
    </IntersectionObserverContext.Provider>
  );
};

const useIntersectionObserver = (): IntersectionObserverType => {
  const context = useContext(IntersectionObserverContext);
  if (context === undefined)
    throw new Error('useIntersectionObserver get an error');
  return context;
};

export { IntersectionObserverProvider, useIntersectionObserver };
